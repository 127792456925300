import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/shared/layout"
import SEO from "../../components/SEO/SEOPage"
import styled from "@emotion/styled"
import { breakpoints, wrapper1200 } from "../../utils/style"
import Breadcrumbs from "../../components/shared/Breadcrumbs"
import Parallax from "../../components/shared/Parallax"

const WrapperDesktop = styled("div")`
  ${wrapper1200}
  position: relative;

  h1 {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 105px;
  }

  .title-background {
    position: absolute;
    top: -10px;
    font-size: 184px;
    text-transform: uppercase;
    object-fit: contain;
    opacity: 0.08;
    color: #ffffff;
    text-align: center;
    display: block;
    width: 100%;
    font-weight: bold;
  }

  @media (max-width: ${breakpoints.lg}) {
    overflow-x: hidden;

    .title-background {
      width: auto;
      transform: translateX(-50%);
      left: 50%;
      white-space: nowrap;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    .title-background {
      font-size: 94px;
      top: 30px;
    }
  }

  @media (max-width: ${breakpoints.sm}) {
    h1 {
      font-size: 24px;
      margin: 30px auto 60px;
    }
  }
`

const ContentWrapper = styled("div")`
  max-width: 1254px;
  margin: 50px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .demo-game-item {
    width: 19%;
    max-width: 225px;
    height: 155px;
    margin-bottom: 30px;
    position: relative;

    &:hover {
      .game-link {
        visibility: visible;
        opacity: 1;
        transition: 0.3s;
      }

      .gatsby-image-wrapper {
        transition: 0.3s;
        filter: brightness(0.5);
      }
    }

    .game-link {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      visibility: hidden;
      opacity: 0;
      transition: 0.3s;
      padding: 0 18px;
      text-transform: uppercase;
      line-height: 48px;
      font-weight: bold;
      width: 115px;
    }

    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      transition: 0.3s;
    }

    @media (max-width: ${breakpoints.lg}) {
      height: auto;
    }

    @media (max-width: ${breakpoints.md}) {
      width: 24%;
      height: auto;
    }

    @media (max-width: ${breakpoints.sm}) {
      max-width: none;
      width: 48%;
      height: auto;
    }
  }
`

const PageTemplate = ({ data, pageContext }) => {
  const currentPage = data.wordpressPage
  const metaTitle =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_title
      ? currentPage.yoast_meta.yoast_wpseo_title
      : currentPage.title + "- " + process.env.OWNER
  const metaDesc =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_metadesc
      ? currentPage.yoast_meta.yoast_wpseo_metadesc
      : ""
  const page_title = pageContext.page_title
  const breadcrumbs = pageContext.breadcrumbs
  const demoGamesList = data.allWordpressPost.edges
  const socialImage = currentPage.featured_media?.localFile?.publicURL

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={currentPage.path}
        breadcrumbs={breadcrumbs}
        socialImage={socialImage}
      />
      <Parallax />
      <WrapperDesktop>
        <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title} />
        <h1>{currentPage.title}</h1>
        <span className="title-background">{currentPage.title}</span>

        <ContentWrapper>
          {demoGamesList.map(game => {
            return (
              <Link
                key={game.node.id}
                to={"/" + game.node.slug + "/"}
                className="demo-game-item"
              >
                <Img
                  fluid={
                    game.node.featured_media.localFile.childImageSharp.fluid
                  }
                  alt={game.node.featured_media.alt_text}
                  fadeIn={false}
                  loading="eager"
                />
                <span className="game-link button gradient-button">
                  ทดลองเล่น
                </span>
              </Link>
            )
          })}
        </ContentWrapper>
      </WrapperDesktop>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      content
      date(formatString: "MMMM DD, YYYY")
      path
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        page_template
      }
      featured_media {
        alt_text
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    allWordpressPost(
      filter: { categories: { elemMatch: { name: { eq: "Demo games" } } } }
    ) {
      edges {
        node {
          id
          title
          content
          slug
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1400) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
